<template>
  <div id="breaches-no-pwd-stats">
    <!--begin::progress-breaches-->
    <b-card
      v-if="data.totalbreachesnopwd"
      no-body
      :class="
        `card card-custom bg-light-${callProgressBarColor(
          this.data.totalbreachesnopwdsolved,
          this.data.totalbreachesnopwd
        )} card-stretch gutter-b`
      "
    >
      <!--begin::body-->
      <div class="card-body">
        <div
          class="d-flex flex-column font-weight-bold text-dark-50 font-size-sm"
        >
          <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">
            {{
              $n(
                this.data.totalbreachesnopwdsolved /
                  this.data.totalbreachesnopwd,
                "percent"
              )
            }}
          </span>
          <span>
            {{
              $t(`tables.abs.breachProgressSolved.${type}`, {
                totalbreachessolved: data.totalbreachesnopwdsolved,
                totalbreaches: data.totalbreachesnopwd
              })
            }}
          </span>
        </div>
        <div
          :class="
            `progress progress-xs mt-7 bg-${callProgressBarColor(
              this.data.totalbreachesnopwdsolved,
              this.data.totalbreachesnopwd
            )}-o-60`
          "
        >
          <!--begin::progress-bar-->
          <div
            :class="
              `progress-bar bg-${callProgressBarColor(
                this.data.totalbreachesnopwdsolved,
                this.data.totalbreachesnopwd
              )}`
            "
            :style="
              `width: ${this.callCalculatePercent(
                this.data.totalbreachesnopwdsolved,
                this.data.totalbreachesnopwd
              )}%; opacity: 0.8`
            "
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <!--end::progress-bar-->
        </div>
      </div>
      <!--end::body-->
    </b-card>
    <!--end::progress-breaches-->
  </div>
</template>
<script>
import { calculatePercent, progressBarColor } from "@/utils/helpers";

export default {
  name: "BreachesProgressNoPwd",
  props: {
    data: {
      type: Object || Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    callCalculatePercent(solved, total) {
      return calculatePercent(solved, total);
    },
    callProgressBarColor(solved, total) {
      return progressBarColor(solved, total);
    }
  }
};
</script>
