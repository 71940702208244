<template>
  <div v-if="absDashboard !== false">
    <b-row>
      <b-col lg="3" md="12" sm="12">
        <!-- <alerts :data="alerts" type="company" :half="true" /> -->
        <breaches-progress-pwd
          v-if="absDashboard.abs.value.global"
          :data="absDashboard.abs.value"
          type="company"
        />
        <breaches-progress-no-pwd
          v-if="absDashboard.abs.value.global"
          :data="absDashboard.abs.value"
          type="company"
        />
      </b-col>
      <b-col lg="9" md="12" sm="12">
        <latest-breaches :data="absDashboard"></latest-breaches>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import Alerts from "@/components/dashboard/Alerts.component";
import BreachesProgressPwd from "@/components/dashboard/breaches-progress/BreachesProgressPwd.component";
import BreachesProgressNoPwd from "@/components/dashboard/breaches-progress/BreachesProgressNoPwd.component";
import LatestBreaches from "@/components/dashboard/latest-breaches-company/LatestBreaches.component";
export default {
  name: "AbsDashboard",
  components: {
    // Alerts,
    BreachesProgressPwd,
    BreachesProgressNoPwd,
    LatestBreaches
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Company", ["absDashboard"]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    alerts() {
      let result = [];
      const unsolved = this.absDashboard.abs.value
        ? this.absDashboard.abs.value.totalbreaches -
          this.absDashboard.abs.value.totalbreachessolved
        : 0;
      const passwordUnsolved = this.absDashboard.abs.value
        ? this.absDashboard.abs.value.totalbreachespwd -
          this.absDashboard.abs.value.totalbreachespwdsolved
        : 0;

      if (unsolved > 0 && passwordUnsolved > 0) {
        result.push({
          type: "breach",
          level: "danger",
          levelNo: 1,
          total: passwordUnsolved,
          icon: "shield-disabled.svg"
        });
      }
      if (unsolved > 0 && parseInt(unsolved) - parseInt(passwordUnsolved) > 0) {
        result.push({
          type: "breach",
          level: "warning",
          levelNo: 2,
          total: parseInt(unsolved) - parseInt(passwordUnsolved),
          icon: "warning-triangle.svg"
        });
      }
      if (
        this.absDashboard.abs.value &&
        this.absDashboard.abs.value.totalbreaches ===
          this.absDashboard.abs.value.totalbreachessolved
      ) {
        if (this.absDashboard.abs.value.totalbreaches === 0) {
          result.push({
            type: "breach",
            level: "success",
            levelNo: 3,
            total: 0,
            counter: 1,
            icon: "double-check.svg"
          });
        } else {
          result.push({
            type: "breach",
            level: "success",
            levelNo: 3,
            total: 0,
            counter: 2,
            icon: "double-check.svg"
          });
        }
      }
      if (!this.absDashboard.abs.value) {
        result.push({
          type: "breach",
          level: "success",
          levelNo: 3,
          total: 0,
          counter: 1,
          icon: "double-check.svg"
        });
      }

      return result;
    }
  },
  methods: {
    ...mapActions("Company", ["loadAbsDashboard"])
  },
  mounted() {
    if (this.isConstantsActive) {
      this.loadAbsDashboard();
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadAbsDashboard();
    }
  }
};
</script>
