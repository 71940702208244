<template>
  <div v-if="data.length">
    <b-table
      id="latest-breaches-company-table"
      hover
      :fields="fields"
      :items="data"
      responsive="sm"
      stacked="sm"
      :per-page="perPage"
      :current-page="currentPage"
      small
    >
      <template #cell(logoPath)="row">
        <div
          class="logo-container"
          :style="'background-image: url(' + row.item.breach.logoPath + ')'"
        ></div>
      </template>
      <template #cell(name)="row">
        {{ row.item.breach.name }}
      </template>
      <template #cell(breachDate)="row">
        {{ row.item.breach.breachDate }}
      </template>
      <template #cell(password)="row">
        {{
          row.item.stakeholders[0].status.password
            ? $tc(`admin.form.yes`)
            : $tc(`admin.form.no`)
        }}
      </template>
      <template #cell(resolved)="row">
        {{ countSolved(row.item) }} /
        {{ row.item.stakeholders.length }}
      </template>
      <template #cell(check)="row">
        <b-icon-check
          variant="success"
          scale="2"
          v-if="completed(row.item)"
        ></b-icon-check>
        <b-icon-exclamation-triangle
          scale="2"
          variant="danger"
          v-else
        ></b-icon-exclamation-triangle>
      </template>
    </b-table>
    <div class="flex-wrap mx-auto" v-if="total > 0">
      <b-pagination
        class="d-flex flex-wrap justify-content-center align-items-center mt-3 py-2 mr-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="companies-table"
        first-number
        pill
        last-number
        size="lg"
      ></b-pagination>
    </div>
  </div>
  <div v-else>
    <empty-card type="company.abs" />
  </div>
</template>

<script>
import _ from "lodash";
import EmptyCard from "@/components/EmptyCard.component";
import { BIconCheck, BIconExclamationTriangle } from "bootstrap-vue";

export default {
  name: "LatestBreachesCompany",
  data() {
    return {
      perPage: 10,
      currentPage: 1
    };
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  components: {
    EmptyCard,
    BIconCheck,
    BIconExclamationTriangle
  },
  computed: {
    fields() {
      return [
        {
          key: "logoPath",
          label: "",
          sortable: false,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "name",
          label: this.$tc("tables.name", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return item.breach.name;
          }
        },
        {
          key: "breachDate",
          label: this.$tc("tables.breachDate", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return item.breach.breachDate;
          }
        },
        {
          key: "password",
          label: this.$tc("tables.password", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return item.stakeholders[0].status.password;
          }
        },
        {
          key: "resolved",
          label: this.$tc("tables.resolved", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return _.countBy(item.stakeholders, s => s.status.solved).true;
          }
        },
        {
          key: "check",
          label: this.$tc("tables.completed", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return this.completed(item);
          }
        }
      ];
    },
    total() {
      return _.size(this.data);
    }
  },
  methods: {
    countSolved(item) {
      return _.countBy(item.stakeholders, s => s.status.solved).true || 0;
    },
    completed(item) {
      return item.stakeholders.length === this.countSolved(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.card.card-custom.card-stretch.gutter-b {
  height: auto;
}
.has-badge {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.7rem;
}
.is-link {
  cursor: pointer;
}

.logo {
  height: 100%;
  width: 100%;
  background-color: #ebedf3;
  padding: 5px;
}

.logo-container {
  margin: 0 1rem;
  padding: 1rem 0;
  height: 3.5rem;
  width: 3.5rem;
  background-repeat: no-repeat;
  background-size: 80%;
  background-color: #ebedf3;
  background-position: center;
}
</style>
