<template>
  <div>
    <b-card
      no-body
      class="card-custom card-stretch gutter-b latest-breaches"
      v-if="true"
    >
      <div class="card-header border-0">
        <h3 v-if="table" class="card-title font-weight-bolder text-dark">
          {{ $tc("dashboard.lastBreaches.company.title") }}
        </h3>
        <h3 v-else class="card-title font-weight-bolder text-dark">
          {{ $tc("dashboard.lastBreaches.users.title") }}
        </h3>
        <div class="card-toolbar">
          <div class="d-flex justify-content-center mt-4">
            <b-button
              class="font-weight-bolder mr-2"
              :variant="table ? 'primary' : 'outline-secondary'"
              pill
              @click="table = true"
            >
              {{ $tc("dashboard.lastBreaches.company.btn") }}
            </b-button>
            <b-button
              class="font-weight-bolder"
              :variant="!table ? 'primary' : 'outline-secondary'"
              pill
              @click="table = false"
            >
              {{ $tc("dashboard.lastBreaches.users.btn") }}
            </b-button>
          </div>
        </div>
      </div>

      <!--begin::Body-->
      <div class="card-body pt-0">
        <latest-breaches-company
          v-if="table"
          :data="data.topBreaches.value"
        ></latest-breaches-company>
        <latest-breaches-users-company
          v-else
          :data="usersBreaches"
        ></latest-breaches-users-company>
      </div>
    </b-card>
    <empty-card v-else type="company.abs" />
  </div>
</template>

<script>
import EmptyCard from "@/components/EmptyCard.component";
import LatestBreachesCompany from "./LatestBreachesCompany.component";
import LatestBreachesUsersCompany from "./LatestBreachesUsersCompany.component";
import { getUsersBreaches } from "@/utils/helpers";

export default {
  name: "LatestBreachesOfCompany",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      table: true,
      breaches: [],
      usersBreaches: []
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    EmptyCard,
    LatestBreachesCompany,
    LatestBreachesUsersCompany
  },
  mounted() {
    this.usersBreaches = getUsersBreaches(
      this.data.topBreaches.value,
      this.data.stakeholders
    );
  }
};
</script>

<style>
.latest-breaches
  .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.latest-breaches .btn.btn-primary:focus:not(.btn-text),
.latest-breaches .btn.btn-primary.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #0bb783;
  border-color: #0bb783;
}
</style>
