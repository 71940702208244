var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"breaches-pwd-stats"}},[(_vm.data.totalbreachespwd)?_c('b-card',{class:`card card-custom bg-light-${_vm.callProgressBarColor(
        this.data.totalbreachespwdsolved,
        this.data.totalbreachespwd
      )} card-stretch gutter-b`,attrs:{"no-body":""}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex flex-column font-weight-bold text-dark-50 font-size-sm"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder font-size-h2 mr-2"},[_vm._v(" "+_vm._s(_vm.$n( this.data.totalbreachespwdsolved / this.data.totalbreachespwd, "percent" )))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t(`tables.abs.pwdProgressSolved.${_vm.type}`, { totalbreachespwdsolved: _vm.data.totalbreachespwdsolved, totalbreachespwd: _vm.data.totalbreachespwd }))+" ")])]),_c('div',{class:`progress progress-xs mt-7 bg-${_vm.callProgressBarColor(
            this.data.totalbreachespwdsolved,
            this.data.totalbreachespwd
          )}-o-60`},[_c('div',{class:`progress-bar bg-${_vm.callProgressBarColor(
              this.data.totalbreachespwdsolved,
              this.data.totalbreachespwd
            )}`,style:(`width: ${_vm.callCalculatePercent(
              this.data.totalbreachespwdsolved,
              this.data.totalbreachespwd
            )}%; opacity: 0.8`),attrs:{"aria-valuemin":"0","aria-valuemax":"100"}})])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }